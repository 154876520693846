<template>
  <th-tab-page-wrapper :headers="headers" :persisted-query="persistedQuery">
    <router-view
      :key="$route.fullPath"
      :resources="resources"
      @route-filter="handleRouteFilter"
    />
  </th-tab-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import pick from 'just-pick'
import { getNavigation } from './navigation'
import { useAppConfigStore } from '@/store/app-config'
import { storeToRefs } from 'pinia'

export default {
  name: 'Statistics',
  setup() {
    const appConfigStore = useAppConfigStore()
    const { featureConfig } = storeToRefs(appConfigStore)
    return { featureConfig }
  },
  data() {
    return {
      resources: {},
      persistedQuery: undefined
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation'
    }),
    headers() {
      const headers = getNavigation({ featureConfig: this.featureConfig })
      return headers
        .filter((item) => {
          return (
            item.enabled === true &&
            this.$checkPermissions({ scopes: item.scopes })
          )
        })
        .map((item) => {
          return {
            label: this.$t(item.key),
            key: item.key,
            value: item.value
          }
        })
    }
  },
  watch: {
    currentLocation: 'fetchResources'
  },
  async created() {
    try {
      await this.fetchResources()
    } catch (err) {
      this.$logException(err, { trackError: false })
    }
  },
  methods: {
    handleRouteFilter(filter) {
      this.persistedQuery = { filter }
    },
    async fetchResources() {
      const res = await this.$resourceFetch(
        {
          resource: 'branchesV1',
          prop: 'branches'
        },
        {
          resource: 'staff',
          query: { location: this.currentLocation }
        },
        {
          resource: 'registers',
          query: { branch: this.currentLocation }
        }
      )
      const cachedRes = { ...res }

      if (Array.isArray(cachedRes.branches))
        cachedRes.branchesMap = new Map(
          cachedRes.branches.map((item) => [
            item.id,
            pick(item, [
              'id',
              'name',
              'branch_number',
              'custom_id',
              'deleted',
              'timezone_default'
            ])
          ])
        )

      if (Array.isArray(cachedRes.staff))
        cachedRes.staffMap = new Map(
          cachedRes.staff.map((item) => [
            item.id,
            pick(item, [
              'id',
              'firstname',
              'lastname',
              'displayname',
              'email',
              'staff_number',
              'deleted'
            ])
          ])
        )
      if (Array.isArray(cachedRes.registers))
        cachedRes.registersMap = new Map(
          cachedRes.registers.map((item) => [
            item.id,
            pick(item, [
              'id',
              'name',
              'register_number',
              'branch_number',
              'deleted',
              'branch'
            ])
          ])
        )

      this.resources = cachedRes
    }
  }
}
</script>
